import { combineReducers } from "redux";
import assignments from "./assignments";
import auth from "./auth";
import users from "./users";
import submissions from "./submissions";
import therapists from "./therapists";
import uiState from "./uiState";
import patients from "./patients";
import userLogs from "./userLogs";
import notes from "./notes";
import resources from "./resources";
import subscriptions from "./subscriptions";
import templateTags from "./templateTags";
import plans from "./plans";

export default combineReducers({
  assignments,
  auth,
  users,
  submissions,
  therapists,
  uiState,
  patients,
  userLogs,
  notes,
  resources,
  subscriptions,
  templateTags,
  plans,
});
