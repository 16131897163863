import "./App.styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { BRAND_NAME } from "utils/constants";
import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AuthenticationLayout, AppLayout } from "layouts";
import { AdminRoute, PageLoading } from "components";
import { ToastContainer } from "react-toastify";

// Admin Pages
import {
  DashboardPage,
  TherapistPage,
  PatientPage,
  SubscriptionsPage,
  UserLogsPage,
  TemplateTagsPage,
  PlansPage,
  LibraryResourcesPage,
} from "./Routes/AdminRoutes";

// Authentication Pages
const LogIn = lazy(() => import("pages/AuthenticationPages/LogIn"));
const TwoFactorAuthPage = lazy(
  () => import("pages/AuthenticationPages/TwoFactorAuth"),
);

// Shared Misc Pages
const SettingsPage = lazy(() => import("pages/AppPages/Settings"));
const NotFound = lazy(() => import("pages/NotFoundPage"));

function App() {
  return (
    <div className="App" data-testid={BRAND_NAME} id="app">
      <Suspense fallback={<PageLoading />}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>

            <Route path="/login" exact>
              <AuthenticationLayout fullHeight>
                <LogIn />
              </AuthenticationLayout>
            </Route>

            <Route path="/login/2fa">
              <AuthenticationLayout fullHeight>
                <TwoFactorAuthPage />
              </AuthenticationLayout>
            </Route>

            <Route path="/settings">
              <AppLayout>
                <SettingsPage />
              </AppLayout>
            </Route>

            <AdminRoute path="/dashboard">
              <AppLayout>
                <DashboardPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/therapists" exact>
              <AppLayout>
                <TherapistPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/therapists/:uuid">
              <AppLayout>
                <UserLogsPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/patients" exact>
              <AppLayout>
                <PatientPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/patients/:uuid">
              <AppLayout>
                <UserLogsPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/subscriptions" exact>
              <AppLayout>
                <SubscriptionsPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/subscriptions/:uuid">
              <AppLayout>
                <SubscriptionsPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/plans" exact>
              <AppLayout>
                <PlansPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/template-tags" exact>
              <AppLayout>
                <TemplateTagsPage />
              </AppLayout>
            </AdminRoute>

            <AdminRoute path="/library-resources" exact>
              <AppLayout>
                <LibraryResourcesPage />
              </AppLayout>
            </AdminRoute>

            <Route component={NotFound} />
          </Switch>
        </Router>
      </Suspense>
      <ToastContainer progressClassName="custom-toast-progress" />
    </div>
  );
}

export default App;
