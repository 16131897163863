import { useState } from "react";
import { updatePlanAction } from "store/actions/plans";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";
import useFetchPlans from "./useFetchPlans";

const useUpdatePlan = ({ planId }) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchAllPlans } = useFetchPlans();

  const submitUpdatePlan = (args) => {
    if (!args) {
      return null;
    }

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
              mutation {
                updatePlan(plan: {
                  name: "${args.name}",
                  patientLimit: ${args.patientLimit},
                  assignmentLimit: ${args.assignmentLimit},
                  customAssignmentLimit: ${args.customAssignmentLimit},
                  customResourceLimit: ${args.customResourceLimit},
                  fileLimit: ${args.fileLimit},
                  uuid: "${planId}",
                }) {
                  uuid
                  name
                  patientLimit
                  assignmentLimit
                  customAssignmentLimit
                  customResourceLimit
                  fileLimit
                  createdAt
                  updatedAt
                }
              }
            `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(
                `${error.errors[0].message || "Hmm, something went wrong."}`,
              );
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`,
            );
            return true;
          } else {
            fetchAllPlans();
            toast("Awesome! Plan Updated!");
            setIsUpdateSuccessful(true);
            return dispatch(updatePlanAction(resData.data.updatePlan));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    submitUpdatePlan,
    isUpdateSuccessful,
  };
};

export default useUpdatePlan;
