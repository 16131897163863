import { lazy } from "react";

const DashboardPage = lazy(() => import("pages/AppPages/AdminPages/Dashboard"));

const TherapistPage = lazy(
  () => import("pages/AppPages/AdminPages/Therapists"),
);

const PatientPage = lazy(() => import("pages/AppPages/AdminPages/Patients"));

const UserLogsPage = lazy(() => import("pages/AppPages/AdminPages/UserLogs"));

const SubscriptionsPage = lazy(
  () => import("pages/AppPages/AdminPages/Subscriptions"),
);

const PlansPage = lazy(() => import("pages/AppPages/AdminPages/Plans"));

const TemplateTagsPage = lazy(
  () => import("pages/AppPages/AdminPages/TemplateTags"),
);

const LibraryResourcesPage = lazy(
  () => import("pages/AppPages/AdminPages/LibraryResources"),
);

export {
  DashboardPage,
  TherapistPage,
  PatientPage,
  UserLogsPage,
  SubscriptionsPage,
  TemplateTagsPage,
  PlansPage,
  LibraryResourcesPage,
};
